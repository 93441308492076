@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Alice&family=Nunito+Sans:wght@200&family=Signika:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Arimo&family=Nunito+Sans:wght@200&family=Signika:wght@300&display=swap');

body {
  background: #789494; 
}

.burger-toggle {
  display: none;
}

.side-nav-menu-container {
  width: 100px;
  background-color: #FFC139;
  height: auto;
  position: fixed;
  z-index: 50;
  right: -100%;
  margin-top: 75px;
  font-size: large;
  margin: 80px 0px 20px 20px;
  padding: 10px;
  color: #416B6B
}

.side-nav-menu-container.active {
  right: 0;
  transition: all 0.4s
}
.burger-icon {
  display: flex;
  float: right;
  color: white;
}

@media only screen and (max-width: 1000px) {
  .nav-toggle {
    display: none;
  } 
  .burger-toggle {
    display: inline-flex
  }
}